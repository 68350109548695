import { mergeParams } from "helpers/_parameters";

const observers = {};
const visible = [];

const defaultConfig = {
    // If the image gets within 50px in the Y axis, start the download.
    rootMargin: "50px 0px",
    threshold: 0.01
}

export function getObserver(callback, config = {}) {
    const args = mergeParams(defaultConfig, config);
    if ("IntersectionObserver" in window) {
        return new IntersectionObserver(callback, args);
    }
    return;
}

// Usage:
// observeElement(inputRef.value, "value", function (oldValue, newValue) {
//     console.log("Input value changed via API. Value changed from '%s' to '%s'", oldValue, newValue);
// });
// Function is used top observe for any DOM manipulation
export function observeElement(element, property, callback, delay = 0) {
    let elementPrototype = Object.getPrototypeOf(element);
    if (elementPrototype.hasOwnProperty(property)) {
        let descriptor = Object.getOwnPropertyDescriptor(elementPrototype, property);
        Object.defineProperty(element, property, {
            get: function() {
                return descriptor.get.apply(this, arguments);
            },
            set: function () {
                let oldValue = this[property];
                descriptor.set.apply(this, arguments);
                let newValue = this[property];
                if (typeof callback == "function") {
                    setTimeout(callback.bind(this, oldValue, newValue), delay);
                }
                return newValue;
            }
        });
    }
}

export const canObserve = ("IntersectionObserver" in window);


export const registerObserver = (uuid, target, callback, config, only_visibility = false) => {
	if (uuid in observers) return;
	observers[uuid] = getObserver((entries, observer) => {
		if (only_visibility && visible.includes(uuid)) {
			// Block from any other intersection
			return;
		}
		// Store the visibility
		if (entries.some(a => a.isIntersecting)) {
			visible.push(uuid);
		}
		// Now trigger the normal callback
		callback(entries, observer);
	}, config);
	observers[uuid].observe(target);
}