import { apiHostname } from "config";

const ID_TOKEN_KEY = "id_token";
const ID_REFRESH_KEY = "id_refresh";
const ID_API_KEY = "id_api";

const wait = ms => new Promise(r => setTimeout(r, ms));

const retryOperation = (operation, delay, retries) => new Promise((resolve, reject) => {
	return operation()
		.then(resolve)
		.catch((reason) => {
			if (retries > 0) {
				return wait(delay)
					.then(retryOperation.bind(null, operation, delay, retries - 1))
					.then(resolve)
					.catch(reject);
			}
			return reject(reason);
		});
});

let isFetchingToken = false;
export const fetchToken = (body = null, refresh = false) => {
	let headers;
	let url;
	if (refresh) {
		url = `${apiHostname}/api/v1.0/auth/jwt/refresh_token`;
		headers = getBearer(ID_REFRESH_KEY);
	} else {
		url = `${apiHostname}/api/v1.0/auth/jwt/access_token`;
		body = new URLSearchParams(body || window.apiUser);
	}
	return fetch(url, {
		method: 'POST',
		headers,
		body
	}).then((result) => {
		if (result.status === 200) {
			// saveToken
			return result.json();
		} else {
			destroyToken(ID_TOKEN_KEY);
			destroyToken(ID_REFRESH_KEY);
			if (!window.location.pathname === "/login") {
				window.location = "/login";
			}
			return;
		}
	}).then((data) => {
		if (!data) return;
		saveToken(data.data.access_token);
		saveToken(data.data.refresh?.token ?? "", ID_REFRESH_KEY);
		setTimeout(() => {
			isFetchingToken = false;
		}, 200);
		return data;
	});
};

function checkFetch () {
	return new Promise((resolve, reject) => {
		if (!isFetchingToken) {
			resolve();
		} else {
			reject("Fetching token is taking longer!");
		}
	});
}
export const refreshToken = (retries = 15) => {
	return new Promise((resolve, reject) => {
		const refresh_token = getToken(ID_REFRESH_KEY);
		if (isFetchingToken) {
			retryOperation(checkFetch, 200, refresh_token ? 15 : retries)
				.then(resolve)
				.catch(reject);
		} else {
			isFetchingToken = true;
			if (refresh_token) {
				fetchToken(null, true).then(resolve);
			} else {
				fetchToken().then(resolve);
			}
		}
	});
};

/**
 * @description get token form localStorage
 */
export const getToken = (key = ID_TOKEN_KEY) => {
	return window.localStorage.getItem(key);
};

// Check if we have login information
export const checkLogin = (key = ID_TOKEN_KEY) => {
	// For now just check for token
	return (window.localStorage.getItem(ID_TOKEN_KEY) !== null);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token, key = ID_TOKEN_KEY) => {
	window.localStorage.setItem(key, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (key = ID_TOKEN_KEY) => {
	window.localStorage.removeItem(key);
};

export const getApiId = () => {
	return getToken(ID_API_KEY);
};
export const initApiId = () => {
	saveToken(window.apiUser.user_id, ID_API_KEY);
};
export const destroyTokens = () => {
	destroyToken(ID_TOKEN_KEY);
	destroyToken(ID_REFRESH_KEY);
};

export const getBearer = (key = ID_TOKEN_KEY) => {
	if (window.apiUser) {
		if (getApiId() !== window.apiUser.user_id) {
			initApiId();
			destroyTokens();
		}
	}
	return {
		"Authorization" : `Bearer ${getToken(key)}`,
		"authenticationmethod" : "JWT"
	};
};
