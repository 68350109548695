<template>
    <router-view />
    <notificationElement />
</template>

<script>
import { defineComponent, provide, reactive, watch, onMounted, computed } from "vue";
import {useRoute, useRouter} from "vue-router";

import notificationElement from "components/notifications";

import { checkLogin, destroyToken} from "services/JwtService";

import { formatData } from "helpers/_data";
import { getDataAsync, getDataWithoutLimit } from "helpers/_api";
import { mergeDeep } from "helpers/_objects";

import { init, user_id, portalStore } from "store/portal";
import { default as config, apiHostname } from "config";

// Our styles
import styles from 'css/dashboard.scss';

const ICON_KEY = "app_icons";

export default defineComponent({
    name: "app",
    components: {
        notificationElement
    },
    setup() {

        const route = useRoute();
        const router = useRouter();

        const currentPayload = computed(() => {
            return route.params.payload ? JSON.parse(atob(route.params.payload)) : null;
        });
        provide("currentPayload",currentPayload);

        const site_id = computed(() => {
            return currentPayload.value?.site_id ?? "1";
        });
        const module = computed(() => {
            return currentPayload.value?.module ?? "";
        });
        provide("site_id",site_id);
        provide("module",module);

        const moduleConfigMap = {
            WidgetGespreksstarter: {
                title: "Gespreksstarter",
                icon: "content_check"
            },
            WidgetKeuzehulp: {
                title: "Keuzehulp",
                icon: "grid--list"
            },
            WidgetAlert: {
                title: "Alert",
                icon: "info"
            }
        };


        const appData = reactive({
            site: [],
            config: [],
            icons: JSON.parse(window.localStorage.getItem(ICON_KEY)) ?? {}
        });
        watch(
            () => appData.icons,
            (icons) => {
                if (!Object.keys(icons).length) return;
                window.localStorage.setItem(ICON_KEY, JSON.stringify(appData.icons));
            },
            {
                immediate: true,
                deep: true
            }
        );
        provide("appData", appData);

        // Fetch functions
        const getItems = (module_access, id, filters = []) => {
            return new Promise((resolve, reject) => {
                let apiUrl = config.value[module_access].url;
                // If ID exists, pass it to the url
                id && (apiUrl = `${apiUrl}/${id}`);
                const filterArray = [
                    [
                        "FieldFilter",
                        {
                            "column": "state",
                            "value": "Online"
                        }
                    ],
                    ...filters
                ];
                const tmpResult = {
                    data: []
                };
                getDataWithoutLimit(`${apiHostname}${apiUrl}`, filterArray, tmpResult, "data").then(() => {
                    const tmpData = tmpResult.data.map(a => {
                        return {data: a};
                    });
                    resolve(tmpData);
                });
            });
        };
        const getItemsAndStore = (module_access, id, filters = []) => {
            return new Promise((resolve, reject) => {
                getItems(module_access, id, filters).then(result => {
                    appData[module_access] = result;
                    resolve(appData[module_access]);
                });
            });
        };

        const enabled_site_ids = computed(() => {
            return appData["site"].map(a => a.data.id);
        });
        const entity = (module_access, id) => {
            return computed(() => {
                return appData[module_access].find(a => a.data.id === id);
            }).value;
        };
        provide("entity",entity);

        const config_field = (key) => {
            return computed(() => {
                // console.log("appData.config",appData.config);
                return appData.config.find(a => a.data.key === key);
            }).value;
        };
        const config_values = (key) => {
            return computed(() => {
                return config_field(key)?.data?.extra?.configvalue?.map(a => {
                    if ((a.serialized === "Y")
                        && (typeof a.value === "string")
                    ) {
                        a.value = JSON.parse(a.value) ?? "";
                    }
                    return a;
                }) ?? "";
            }).value;
        };
        const config_value = (key, target_site_id) => {
            return computed(() => {
                target_site_id = target_site_id ?? site_id.value;
                if (target_site_id === null) return "";
                // Check for values
                const values = config_values(key);
                if (values) {
                    return values.find(a => `${a.site_id}` === `${target_site_id}`)?.value ?? values.find(a => `${a.site_id}` === `0`)?.value ?? "";
                }
                return "";
            }).value;
        };
        provide("config_field", config_field);
        provide("config_value", config_value);
        provide("config_values", config_values);

        const widget_url = (module) => {
            return computed(() => {
                if (!appData.config.length) return null;
                const access = module_access(module);
                let url = config_value(`site.widgets.${access}_url`) || `${access}.widgets.ibizz.nl`;
                url = url.replace(/(^\w+:|^)\/\//, "");
                return `//${url}`;
            }).value;
        };
        provide("widget_url", widget_url);


        const module_config = (module) => {
            return computed(() => {
                return moduleConfigMap[module] ?? {};
            }).value;
        };
        const module_access = (module) => {
            return computed(() => {
                if (!module) return "";
                console.log("module",module);
                return module.replace(/^Widget/, "").toLowerCase();
            }).value;
        };
        const module_title = (module) => {
            return computed(() => {
                return moduleConfigMap[module]?.title ?? module;
            }).value;
        };
        const module_icon = (module) => {
            return computed(() => {
                return moduleConfigMap[module]?.icon ?? "";
            }).value;
        };
        provide("module_config", module_config);
        provide("module_access", module_access);
        provide("module_title", module_title);
        provide("module_icon", module_icon);

        const handleClick = (url, query = {}) => {

            const payloadQuery = Object.assign(query, {});
            // Append navigation configuration
            payloadQuery.site_title = entity("site", query.site_id)?.data.title ?? "";
            payloadQuery.config = module_config(query.module);

            const payloadData = btoa(JSON.stringify(payloadQuery));

            if (Object.keys(query).length) {
                return router.push(`${url}/${payloadData}`);
            }
            return router.push(url);
        };
        provide("handleClick", handleClick);

        let userWatcher;
        onMounted(() => {
            init();
            watch(
                () => user_id.value,
                () => {
                    if (!user_id.value) return;

                    userWatcher = watch(
                        () => route.path,
                        (path) => {
                            if (!checkLogin() || path === "/login") return;
                            getItemsAndStore("site");

                            watch(
                                () => enabled_site_ids.value,
                                (site_ids) => {
                                    if (!site_ids.length) {
                                        appData.config = [];
                                        return;
                                    }
                                    getItemsAndStore("config", null, [
                                        [
                                            "FieldFilter",
                                            {
                                                "column": "key",
                                                "value": "site.widgets.%",
                                                "operator": "LIKE"
                                            }
                                        ],
                                        [
                                            "FieldFilter",
                                            {
                                                "column": "site_id",
                                                "value": site_ids
                                            }
                                        ]
                                    ]);
                                },
                                {
                                    immediate: true
                                }
                            );
                            userWatcher && userWatcher();
                        },
                        {
                            immediate: true
                        }
                    );
                }
            );
        });


        return {
        }
    }
});
</script>